import { useEffect } from 'react';
import ReactGA from "react-ga4";
// import userActions from 'actions/userActions';


export default function AppWrapper() {
	// const { oAuthSession } = userActions();
	// useEffect(() => {
	// 	if (process.env.NEXT_PUBLIC_NODE_ENV !== 'development') {
	// 		(async () => {
	// 			try {
	// 				const { loadGapiInsideDOM } = await import('gapi-script');
	// 				const gapi = await loadGapiInsideDOM();
	// 				await new Promise((resolve, reject) => {
	// 					gapi.load('client:auth2', {
	// 						callback: resolve,
	// 						onerror: reject
	// 					})
	// 				})
	// 				await gapi.auth2.init({ 
	// 					clientId: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID, scope: ''
	// 				})
	// 				if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
	// 					await oAuthSession();
	// 				}
	// 				return;
	// 			} catch (err) {
	// 				return
	// 			}
	// 		})()
	// 	}
	// }, [oAuthSession]);
	useEffect(() => {
		if (process.env.NEXT_PUBLIC_NODE_ENV !== 'development') {
			(async () => {
				try {
					if (!window.GA_INITIALIZED) {
						setTimeout(() => {
							ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID);
							ReactGA.send({ hitType: "pageview", page: window.location.pathname });
							window.GA_INITIALIZED = true;
						}, 2e4)
					}
					return;
				} catch (err) {
					return
				}
			})()
		}
	}, []);

	return (<></>)
}