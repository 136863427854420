import styles from 'styles/Footer.module.scss';
import Link from 'next/link';

export default function Footer() {

	return (
		<footer className={ styles.footer }>
			<section className={ styles.container }>
				<div>
					<p>WiFiGen Web Design & Development</p>
					<hr />
					<ul>
						<li>
							<Link href='mailto:info@chengpham.com'>info@wifigen.ca</Link>
						</li>
					</ul>
				</div>
				<div>
					<p>Navigation</p>
					<hr />
					<ul>
						<li className={ 'navLink' }>
							<Link href='/'>Home</Link>
						</li>
						<li className={ 'navLink' }>
							<Link href='services'>Services</Link>
						</li>
						<li className={ 'navLink' }>
							<Link href='/about'>About</Link>
						</li>
						<li className={ 'navLink' }>
							<Link href='contact'>Contact</Link>
						</li>
					</ul>
				</div>
				<div>
					<p>Services</p>
					<hr />
					<ul>
						<li>Web Design + Development</li>
						<li>Website Maintenance</li>
						<li>SEO Services</li>
						<li>Cloud Hosting</li>
						<li>Content Creation</li>
					</ul>
				</div>
			</section>
			<section className={ styles.copyright }>
				<div>
					Copyright © 2023 | WiFigen Web Design & Development | All Rights Reserved
				</div>
			</section>
		</footer>
	)
}